import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AccountStateTypes, FormDataTypes } from "../../types";
import { constants } from "../../assets/constants/indes";
import { getCurrentTime } from "../../utils";

export const loginAsync = createAsyncThunk("account/loginAsync", async (formData: FormDataTypes | string) => {
    const response = await fetch((formData ? process.env.REACT_APP_API_HOST : `${window.location.href}/api`) + constants.endpoints.login, {
        method: "POST",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: formData ? JSON.stringify(formData) : ''
    });
    if (!response.ok) return response.status;
    const data = await response.json();
    console.log(response.status)
    return data;
});

const initialState: AccountStateTypes = {
    accountLoading: false,
    authorizationError: false,
    user: {
        access_token: "",
        access_token_expire_time: "",
        dn: "",
        email: "",
    },
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        resetAuthorizationError(state) {
            state.authorizationError = false;
        },
        setAccessToken(state, action) {
            state.user.access_token = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.accountLoading = true;
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.accountLoading = false;
                if(typeof action.payload === 'number'){
                    return
                }
                state.user = {
                    access_token: action.payload.access_token,
                    access_token_expire_time: action.payload.access_token_expire_time,
                    dn: action.payload.user.dn,
                    email: action.payload.user.email,
                };
                localStorage.setItem("token", action.payload.access_token);
                localStorage.setItem("email", action.payload.user.email);
                localStorage.setItem("lifeToken", getCurrentTime());
            })
            .addCase(loginAsync.rejected, (state) => {
                state.accountLoading = false;
                state.authorizationError = true;
            });
    },
});
export const { resetAuthorizationError, setAccessToken } = accountSlice.actions;
export default accountSlice.reducer;
