import Loading from "./components/Loading";
import AuthorizationPayAya from "./components/AuthorizationPayAya";
import RootRouter from "./router";

function App() {
    return (
        <div className="App">
            <RootRouter />
            <Loading />
            <AuthorizationPayAya />
        </div>
    );
}

export default App;
