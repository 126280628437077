import { useAppSelector } from "../../store";
import styles from "./loading.module.scss";

function Loading() {
    const accountLoading = useAppSelector((state) => state.account.accountLoading);
    const chatsLoading = useAppSelector((state) => state.chats.chatsLoading);
    const loading = [accountLoading, chatsLoading].some((l) => l);

    return <div className={loading ? styles.loading : styles.hidden}></div>;
}

export default Loading;
