import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { constants } from "../../assets/constants/indes";
import { ChatsStateTypes, CreateChatAsyncPropsTypes, DeleteChatAsyncPropsTypes, GetChatMessagesAsyncPropsTypes } from "../../types";

export const getChatsAsync = createAsyncThunk("account/getChatsAsync", async (accessToken: string) => {
    const response = await fetch(process.env.REACT_APP_API_HOST + constants.endpoints.chats, {
        method: "GET",
        headers: {
            "x-access-token": accessToken,
        },
    });
    if (!response.ok) throw new Error("Authorization error");
    const data = await response.json();
    return data;
});

export const createChatAsync = createAsyncThunk("account/createChatAsync", async ({ name, accessToken }: CreateChatAsyncPropsTypes) => {
    const response = await fetch(process.env.REACT_APP_API_HOST + constants.endpoints.chats, {
        method: "POST",
        headers: {
            "x-access-token": accessToken,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
    });
    if (!response.ok) throw new Error("error");
    const data = await response.json();
    return data;
});

export const deleteChatAsync = createAsyncThunk("account/deleteChatAsync", async ({ id, accessToken }: DeleteChatAsyncPropsTypes) => {
    const response = await fetch(process.env.REACT_APP_API_HOST + constants.endpoints.chats + `/${id}`, {
        method: "DELETE",
        headers: {
            "x-access-token": accessToken,
        },
    });
    if (!response.ok) throw new Error("error");
    return id;
});

export const getChatMessagesAsync = createAsyncThunk("account/getChatMessagesAsync", async ({ id, accessToken }: GetChatMessagesAsyncPropsTypes) => {
    const response = await fetch(process.env.REACT_APP_API_HOST + constants.endpoints.chats + `/${id}/messages`, {
        method: "GET",
        headers: {
            "x-access-token": accessToken,
        },
    });
    if (!response.ok) throw new Error("Authorization error");
    const data = await response.json();
    return data;
});

const initialState: ChatsStateTypes = {
    chatsLoading: false,
    selectedChatIdRedux: "",
    chatsList: [],
    chatMessagesList: [],
};

const chatsSlice = createSlice({
    name: "chats",
    initialState,
    reducers: {
        resetChatMessagesListRedux(state) {
            state.chatMessagesList = [];
        },
        addMessageRedux(state, action) {
            state.chatMessagesList = [...state.chatMessagesList, action.payload];
        },
        removeChat(state, action) {
            const filter = state.chatMessagesList.filter((item) => item.created_datetime !== action.payload)
            state.chatMessagesList = [...filter];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChatsAsync.pending, (state) => {
                state.chatsLoading = true;
            })
            .addCase(getChatsAsync.fulfilled, (state, action) => {
                state.chatsLoading = false;
                state.chatsList = action.payload;
            })
            .addCase(getChatsAsync.rejected, (state) => {
                state.chatsLoading = false;
            })
            .addCase(createChatAsync.pending, (state) => {
                state.chatsLoading = true;
            })
            .addCase(createChatAsync.fulfilled, (state, action) => {
                state.chatsLoading = false;
                state.selectedChatIdRedux = action.payload.id;
                state.chatsList = [action.payload, ...state.chatsList];
            })
            .addCase(createChatAsync.rejected, (state) => {
                state.chatsLoading = false;
            })
            .addCase(deleteChatAsync.pending, (state) => {
                state.chatsLoading = true;
            })
            .addCase(deleteChatAsync.fulfilled, (state, action) => {
                state.chatsLoading = false;
                state.chatsList = state.chatsList.filter((chat) => chat.id !== action.payload);
            })
            .addCase(deleteChatAsync.rejected, (state) => {
                state.chatsLoading = false;
            })
            .addCase(getChatMessagesAsync.pending, (state) => {
                state.chatsLoading = true;
            })
            .addCase(getChatMessagesAsync.fulfilled, (state, action) => {
                state.chatsLoading = false;
                state.chatMessagesList = [...action.payload.reverse(), ...state.chatMessagesList];
            })
            .addCase(getChatMessagesAsync.rejected, (state) => {
                state.chatsLoading = false;
            });
    },
});

export const { resetChatMessagesListRedux, addMessageRedux, removeChat } = chatsSlice.actions;
export default chatsSlice.reducer;
