import { useState, useEffect } from "react";
import { FormDataTypes } from "../../types";
import styles from "./login.module.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import { loginAsync, resetAuthorizationError, setAccessToken } from "../../store/features/accountSlice";
import { isExpiredToken } from "../../utils";

const initialFormData = {
    login: "",
    password: "",
};

function Login() {
    const dispatch = useAppDispatch();
    const { authorizationError } = useAppSelector((state) => state.account);
    const [formData, setFormData] = useState<FormDataTypes>(initialFormData);

    const changeFormDataValues = (e: React.ChangeEvent<HTMLInputElement>) => {
        authorizationError && dispatch(resetAuthorizationError());
        setFormData({ ...formData, [e.target.id]: e.target.value });
    };

    const login = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(loginAsync(formData));
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        const oldTime = localStorage.getItem("lifeToken");

        if(token && oldTime && !isExpiredToken(oldTime)){
            dispatch(setAccessToken(token));
        }else if(token && oldTime && isExpiredToken(oldTime)){
            localStorage.removeItem("token")
        }
    }, [dispatch]);


    return (
        <div className={styles.loginMainWrapper}>
            <form className={`${styles.loginForm} ${authorizationError && styles.authorizationError}`} onSubmit={login}>
                <p className={styles.loginTitle}>Авторизация</p>
                <input type="email" id="login" name="login" className={styles.loginInp} onChange={changeFormDataValues} required autoFocus />
                <input className={styles.passwordInp} type="password" name="password" id="password" onChange={changeFormDataValues} required />
                <button className={styles.loginBtn}>Войти</button>
            </form>
        </div>
    );
}

export default Login;
