import gpt_icon from "../../../assets/images/gpt_icon.png";
import pluse_icon from "../../../assets/images/pluse_icon.png";
import message_icon from "../../../assets/images/message_icon.png";
import delete_icon from "../../../assets/images/delete_icon.png";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { deleteChatAsync, getChatsAsync } from "../../../store/features/chatsSlice";
import { ChatSidebarPropsTypes } from "../../../types";
import styles from "./chat_sidebar.module.scss";

function ChatSidebar(props: ChatSidebarPropsTypes) {
    const { changeSelectedChatId, selectedChatId } = props;
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector((state) => state.account.user.access_token);
    const chatsList = useAppSelector((state) => state.chats.chatsList);

    const deleteChat = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string | number) => {
        e.stopPropagation();
        if (id === selectedChatId) changeSelectedChatId("");
        dispatch(deleteChatAsync({ id, accessToken }));
    };

    useEffect(() => {
        dispatch(getChatsAsync(accessToken));
    }, []);

    return (
        <div className={styles.chatSidebarWrapper}>
            <div className={styles.sidebarHeader}>
                <img src={gpt_icon} alt="gpt_icon" />
                <span>AYA GPT</span>
            </div>
            <div className={styles.newChatBtn} onClick={() => changeSelectedChatId("")}>
                <img src={pluse_icon} alt="pluse_icon" />
                <span>Новый чат</span>
            </div>
            <div className={styles.sidebarItemsWrapper}>
                {chatsList.map((chat) => {
                    return (
                        <div
                            className={`${styles.sidebarItem} ${chat.id === selectedChatId && styles.selected}`}
                            key={chat.id}
                            onClick={() => changeSelectedChatId(chat.id)}
                        >
                            <img src={message_icon} alt="message_icon" />
                            <span>{chat.name}</span>
                            <div className={styles.deleteChatBtn} onClick={(e) => deleteChat(e, chat.id)}>
                                <img src={delete_icon} alt="delete_icon" />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ChatSidebar;
