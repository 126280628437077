import { useEffect, useState } from "react";
import ChatSidebar from "./ChatSidebar";
import DiscussionsPage from "./DiscussionsPage";
import { useAppDispatch, useAppSelector } from "../../store";
import { resetChatMessagesListRedux } from "../../store/features/chatsSlice";

function Chat() {
    const dispatch = useAppDispatch();
    const selectedChatIdRedux = useAppSelector((state) => state.chats.selectedChatIdRedux);
    const [selectedChatId, setSelectedChatId] = useState<string | number>("");

    const changeSelectedChatId = (id: string | number) => {
        setSelectedChatId(id);
        dispatch(resetChatMessagesListRedux());
    };

    useEffect(() => {
        if (selectedChatIdRedux) setSelectedChatId(selectedChatIdRedux);
    }, [selectedChatIdRedux]);

    return (
        <div>
            <ChatSidebar changeSelectedChatId={changeSelectedChatId} selectedChatId={selectedChatId} />
            <DiscussionsPage selectedChatId={selectedChatId} />
        </div>
    );
}

export default Chat;
