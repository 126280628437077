import { Route, Routes } from "react-router-dom";
import { RouteItemTypes } from "../types";
import Login from "../components/Login";
import Chat from "../components/Chat";
import { useAppSelector } from "../store";

const publicRoutes: RouteItemTypes[] = [
    {
        path: "*",
        element: <Login />,
    },
];

const privateRoutes: RouteItemTypes[] = [
    {
        path: "/chat",
        element: <Chat />,
    },
    {
        path: "*",
        element: <Chat />,
    },
];

function RootRouter() {
    const user = useAppSelector((state) => state.account.user);
    return (
        <Routes>
            {(user.access_token ? privateRoutes : publicRoutes).map(({ element, path }) => (
                <Route key={path} path={path} element={element} />
            ))}
        </Routes>
    );
}

export default RootRouter;
