import send_icon from "../../../assets/images/send_icon.png";
import stop_icon from "../../../assets/images/stop_icon.png";
import loading_icon from "../../../assets/images/loading_icon.png";
import { DiscussionsPagePropsTypes } from "../../../types";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { addMessageRedux, createChatAsync, getChatMessagesAsync, removeChat } from "../../../store/features/chatsSlice";
import styles from "./discussions_page.module.scss";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { SyntaxHighlighterProps } from 'react-syntax-highlighter';
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw';
import 'github-markdown-css'

function DiscussionsPage(props: DiscussionsPagePropsTypes) {
    const { selectedChatId } = props;
    const dispatch = useAppDispatch();
    const { access_token: accessToken, email } = useAppSelector((state) => state.account.user);
    const { chatMessagesList } = useAppSelector((state) => state.chats);
    const socketRef = useRef<null | WebSocket>(null);
    const messageInPending = useRef<string>("");
    const containerRef = useRef<null | HTMLDivElement>(null);
    const [message, setMessage] = useState<string>("");
    const [sendLoading, setSndLoading] = useState<boolean>(false);
    const [sendClose, setSendClose] = useState<boolean>(false);
    const [longAnswer, setLongAnswer] = useState<boolean>(false);
    const emailStorage = localStorage.getItem('email') || '';
    const [minHeight, setMinHeight] = useState(42);
    const [getArrMessage, setGetArrMessage] = useState<any>({});
    let getMessage = '';
    let overMoreText = false;
    let addNoneArray = false;
    let randomId = Math.random();

    const correctKeyMessage = (data: any, text: string) =>{
        return data ? 'Генерация ответа невозможна, пожалуйста обратитесь к администратору' : text
    }

    const createWebSocket = () =>{
        if(overMoreText) {
            setMessage('');
            return;
        };
        socketRef.current = new WebSocket(process.env.REACT_APP_API_HOST + `/chats/${selectedChatId}/ws`, accessToken);
        socketRef.current.onopen = function (event) {
            socketRef.current?.send(correctMessage(message));
            dispatch(
                addMessageRedux({
                    author: "user",
                    created_datetime: new Date().toISOString(),
                    files: [],
                    text: message,
                })
            );
        };
        socketRef.current.onmessage = function (data: any) {
            const text = JSON.parse(data.data)
            if(!addNoneArray){
                dispatch(addMessageRedux({
                    text: correctKeyMessage(text.openai_error_code, text.text),
                    author: 'assistant',
                    created_datetime: text.created_datetime || randomId
                }));
                addNoneArray = true;
            }
            if(text.completed){
                setGetArrMessage({})
                dispatch(removeChat(randomId))
                dispatch(addMessageRedux({
                    text: correctKeyMessage(text.openai_error_code, getMessage),
                    author: 'assistant',
                    created_datetime: text.created_datetime
                }));
                setLongAnswer(false);
                setSndLoading(false);
                getMessage = '';
                addNoneArray = false;
                randomId = Math.random();
            }else{
                getMessage += text.text
                setGetArrMessage({
                    text: correctKeyMessage(text.openai_error_code, getMessage),
                })
            }
        };
    }

    const sendMessage = (e?: React.FormEvent<HTMLFormElement>) => {
        if (e) e.preventDefault();
        if(sendLoading) return;
        setSndLoading(true);
        setLongAnswer(true);
        if(overMoreText) {
            setMessage('');
            return;
        };
        setMinHeight(prevMinHeight => prevMinHeight = 42);
        if (!selectedChatId) {
            messageInPending.current = message;
            dispatch(createChatAsync({ name: message.slice(0, 40), accessToken }));
        } else {
            if(!sendClose){
                socketRef.current?.send(correctMessage(message));
                dispatch(
                    addMessageRedux({
                        author: "user",
                        created_datetime: new Date().toISOString(),
                        files: [],
                        text: message,
                    })
                );
            }else{
                setSendClose(false)
                createWebSocket();
            }
        }
        setMessage("");
    };

    const correctMessage = (text: string): string => JSON.stringify({ text });

    const stopSend = () =>{
        if (socketRef.current) {
            socketRef.current.close();
            setSndLoading(false);
            setSendClose(true);
            setLongAnswer(false);
            getMessage = '';
            addNoneArray = false;
            randomId = Math.random();
        }
    }

    useEffect(() => {
        if (containerRef.current && chatMessagesList.length) {
            containerRef.current.scrollTo({
                top: containerRef.current.scrollHeight,
                behavior: "smooth",
            });
        }
    }, [chatMessagesList]);

    useEffect(() => {
        if (selectedChatId) {
            setGetArrMessage({});
            if (socketRef.current) socketRef.current.close();
            if(overMoreText) {
                setMessage('');
                return;
            };
            dispatch(getChatMessagesAsync({ id: selectedChatId, accessToken }));
            socketRef.current = new WebSocket(process.env.REACT_APP_API_HOST + `/chats/${selectedChatId}/ws`, accessToken);
            socketRef.current.onopen = function (event) {
                if (messageInPending.current) {
                    socketRef.current?.send(correctMessage(messageInPending.current));
                    dispatch(
                        addMessageRedux({
                            author: "user",
                            created_datetime: new Date().toISOString(),
                            files: [],
                            text: messageInPending.current,
                        })
                    );
                    messageInPending.current = "";
                }
            };
            socketRef.current.onmessage = function (data: any) {
                const text = JSON.parse(data.data)
                if(!addNoneArray){
                    dispatch(addMessageRedux({
                        text: correctKeyMessage(text.openai_error_code, text.text),
                        author: 'assistant',
                        created_datetime: text.created_datetime || randomId
                    }));
                    addNoneArray = true;
                }
                if(text.completed){
                    setGetArrMessage({});
                    dispatch(removeChat(randomId));
                    dispatch(addMessageRedux({
                        text: correctKeyMessage(text.openai_error_code, getMessage),
                        author: 'assistant',
                        created_datetime: text.created_datetime
                    }));
                    setLongAnswer(false);
                    setSndLoading(false);
                    getMessage = '';
                    addNoneArray = false;
                    randomId = Math.random();
                }else{
                    getMessage += text.text
                    setGetArrMessage({
                        text: correctKeyMessage(text.openai_error_code, getMessage),
                    })
                }
            };
        }
        setLongAnswer(false);
        setSndLoading(false);
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [selectedChatId]);

    useEffect(()=>{
        if(message.length >= 4000) {
            overMoreText = true;
        }else{
            overMoreText = false;
        }
        if(!message.length) setMinHeight(42);
    }, [message])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if((!event.shiftKey && !event.metaKey && !event.altKey) && event.key === 'Enter'){
            event.preventDefault();
            sendMessage()
            return
        }
        if ((event.shiftKey || event.metaKey || event.altKey) && event.key === 'Enter') {
            event.preventDefault();
            const newText = message + '\n';
            setMinHeight(prevMinHeight => prevMinHeight + 16);
            setMessage(newText);
        }
    };

    return (
        <div className={styles.discussionsPageWrapper}>
            <div className={styles.discussionsList} ref={containerRef}>
                {chatMessagesList.map((item, index) => {
                    const isAssistant = item.author === "assistant";
                    return (
                        <div className={`${styles.discussionsListItem} ${isAssistant && styles.assistant}`} key={item.created_datetime}>
                            <p className={styles.nick}>{isAssistant ? "AYA GPT" : (email || emailStorage).replace("@ayagroup.pro", "")}</p>
                            <div className={`${styles.markdownBody} markdown-body`}>
                            <ReactMarkdown
                                children={index + 1 === chatMessagesList.length && getArrMessage.text ? getArrMessage.text : item.text}
                                remarkPlugins={[remarkGfm]}
                                rehypePlugins={[rehypeRaw]}
                                components={{
                                    code({ node, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '');
                                        return match ? (
                                            <SyntaxHighlighter
                                                style={atomDark}
                                                language={match[1]}
                                                PreTag="div"
                                                {...props as SyntaxHighlighterProps}
                                            >
                                                {String(children).replace(/\n$/, '')}
                                            </SyntaxHighlighter>
                                        ) : (
                                            <code className={className} {...props}>
                                                {children}
                                            </code>
                                        );
                                    },
                                }}
                            />
                            </div>
                        </div>
                    );
                })}
            </div>
            <form className={styles.sendMessage} onSubmit={sendMessage}>
                <textarea
                    placeholder="Сообщение"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className={styles.messageInp}
                    style={{ minHeight: `${minHeight}px` }}
                    spellCheck={false}
                    disabled={sendLoading}
                    autoFocus
                ></textarea>
                {!longAnswer &&
                    <button>
                        {sendLoading ? (
                            <img className={styles.loading_icon} src={loading_icon} alt="loading_icon" />
                        ) : (
                            <img src={send_icon} alt="send_icon" />
                        )}
                    </button>
                }
                {longAnswer &&
                    <button onClick={stopSend}>
                        <img src={stop_icon} alt="stop_icon" />
                    </button>
                }
            </form>
        </div>
    );
}

export default DiscussionsPage;
