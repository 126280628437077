export function getCurrentTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    return `${year}:${month}:${day} ${hours}:${minutes}`;
}

export function timeToMilliseconds(timeString:string) {
    const [datePart, timePart] = timeString.split(' ');
    const [year, month, day] = datePart.split(':').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hours, minutes).getTime();
}

export function isExpiredToken(oldTime: string) {
    const currentTime = getCurrentTime();
    const oldTimeMs = timeToMilliseconds(oldTime);
    const currentTimeMs = timeToMilliseconds(currentTime);

    const difference = currentTimeMs - oldTimeMs;

    return difference > 1800000;
}