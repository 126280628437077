import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { loginAsync } from "../../store/features/accountSlice";
import preloader from "../../assets/images/preloader.svg"

function AuthorizationPayAya() {
    const dispatch = useAppDispatch();

    let [errors, setErrors] = useState(false)
    let [loader, setLoader] = useState(true)

    const login = async () => {
        setLoader(true)

        const response = await dispatch(loginAsync(''));
        console.log(response)
        if(typeof response.payload === 'number'){
            setErrors(true)
        }else{
            document.querySelector('.autorizationAya')?.classList.add('fade-out')
        }

        setLoader(false)
    };

    useEffect(() => {
        if(!localStorage.getItem('token') && window.location.hostname === 'pay.ayagroup.pro'){
            login()
        }else{
            setLoader(false)
        }
    }, []);

    console.log(document.referrer)

    return (
        errors || loader ? (
            <div className="autorizationAya">
                {
                    errors ? (
                        <span>Access denied</span>
                    ) : loader ? (
                        <img src={preloader} alt=""/>
                    ) : ''
                }
            </div>
        ) : <div className="autorizationAyaNone"></div>
    )
}

export default AuthorizationPayAya;